import { useState } from "react";
import Header from "./Header";

const AUTH = "idbcdoecdoencdejcd0hcf9uhvf9r3vfr933r93BISBIB@@R#&!#*!"
const MOCKUP = true;

function AppStore() {
  return (
    <div class="flex mt-3 w-48 h-14 bg-black dark:bg-gray-800 text-white rounded-lg items-center justify-center">
      <div class="mr-3">
        <svg viewBox="0 0 384 512" width="30" >
          <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
        </svg>
      </div>
      <div>
        <div class="text-xs">Coming soon on the</div>
        <div class="text-2xl font-semibold font-sans -mt-1">App Store</div>
      </div>
    </div>
  )
}

function PlayStore() {
  return (
    <div class="flex mt-3 w-48 h-14 bg-black dark:bg-gray-800 text-white rounded-lg items-center justify-center">
      <div class="mr-3">
        <svg viewBox="30 336.7 120.9 129.2" width="30">
          <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z" />
          <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z" />
          <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z" />
          <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z" />
        </svg>
      </div>
      <div>
        <div class="text-xs">COMING SOON ON</div>
        <div class="text-xl font-semibold font-sans -mt-1">Google Play</div>
      </div>
    </div>
  )
}

function Body() {

  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);

  const submit = (e) => {
    e.preventDefault();
    return fetch('/join-waitlist', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ "email": email, "password": AUTH })
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.message === "success") {
          console.log("in first if statement...")
          setMessage("Thanks for joining the waitlist! We will reach out soon with updates on the product.")
        } else if (res.message === "duplicate") {
          setMessage("You have already joined the waitlist. We will reach out soon with updates on the product.")
        } else {
          setMessage("Something went wrong. Please refresh the page and try again.")
        }
      })
      .catch(() => setMessage("Something went wrong. Please refresh the page and try again."));
  }

  return (
    <div class={`flex flex-row flex-wrap py-8 px-8 mx-auto max-w-screen-xl text-center z-10 relative space-y-12 md:space-y-0 ${MOCKUP ? "" : "mt-0 md:mt-12"} `}>
      <div className={`w-full ${MOCKUP ? "md:w-1/2" : ""} flex flex-col items-center ${MOCKUP ? "md:items-start" : "md:items-center"} justify-start md:justify-center`}>
        <a href="#" class="hidden md:inline-flex justify-between items-center py-1 px-1 pe-4 mb-7 text-sm text-emerald-700 bg-emerald-100 rounded-full dark:bg-emerald-900 dark:text-emerald-300 hover:bg-emerald-200 dark:hover:bg-emerald-800">
          <span class="text-xs bg-emerald-600 rounded-full text-white px-4 py-1.5 me-3">New</span> <span class="text-sm font-medium">Our waitlist has opened! Sign up below</span>
          <svg class="w-2.5 h-2.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
          </svg>
        </a>
        <div className={`w-full flex flex-col ${MOCKUP ? "items-start" : "items-center"}`}>
          <h1 className={`mb-4 text-5xl font-extrabold tracking-tight leading-none text-gray-900 dark:text-white text-center ${MOCKUP ? "md:text-left" : ""}`}>Track the emissions of your food with one click.</h1>
          <p className={`mb-8 text-lg font-normal text-gray-500 dark:text-gray-200 text-center ${MOCKUP ? "md:text-left" : ""}`}>Our powerful AI tools use computer vision, LLMs, and complex data pipelines to instantly calculate the emissions and other environmental impacts of your meals. Our app allows you to keep track of your food's footprint over time and join friend groups to share your progress.</p>
        </div>
        {message ?
          <p className={`font-semibold text-emerald-600 text-center ${MOCKUP ? "md:text-left" : ""}`}>{message}</p>
          :
          <>
            <form class="w-full max-w-md" onSubmit={submit}>
              <div class="relative hidden sm:block">
                <div class="absolute inset-y-0 rtl:inset-x-0 start-0 flex items-center ps-3.5 pointer-events-none">
                  <svg class="w-4 h-4 text-emerald-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                    <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                    <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                  </svg>
                </div>
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 focus:ring-emerald-600 focus:!outline-none rounded-lg bg-white 
            focus:border-emerald-600 focus:ring-2 focus:ring-emerald-600 dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 
            dark:text-white dark:focus:border-emerald-500"
                  placeholder="Enter your email here..." required />
                <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-emerald-700 hover:bg-emerald-800 focus:ring-4 focus:outline-none focus:ring-emerald-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-emerald-600 dark:hover:bg-emerald-700 dark:focus:ring-emerald-800">Sign up</button>
              </div>
              <div className="block sm:hidden space-y-4">
                <div class="relative block sm:hidden">
                  <div class="absolute inset-y-0 rtl:inset-x-0 start-0 flex items-center ps-3.5 pointer-events-none">
                    <svg class="w-4 h-4 text-emerald-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                      <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                      <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                    </svg>
                  </div>
                  <input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 focus:ring-emerald-600 focus:!outline-none rounded-lg bg-white 
            focus:border-emerald-600 focus:ring-2 focus:ring-emerald-600 dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 
            dark:text-white dark:focus:border-emerald-500"
                    placeholder="Enter your email here..." required />
                </div>
                <button type="submit" className="w-full text-white end-2.5 bottom-2.5 bg-emerald-700 hover:bg-emerald-800 focus:ring-4 focus:outline-none focus:ring-emerald-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-emerald-600 dark:hover:bg-emerald-700 dark:focus:ring-emerald-800">Sign up</button>
              </div>
            </form>
            <p className="text-xs font-medium text-emerald-600 text-center md:text-left mt-1">*By joining the waitlist, you consent to receive communications regarding our product.</p>
          </>}
        <div className="mt-3">
          <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-4">
            <AppStore />
            <PlayStore />
          </div>
        </div>
      </div>
      <div class={`w-full md:w-1/2 flex items-center justify-center relative ${MOCKUP ? "" : "hidden"}`}>
        <div class="relative md:hidden mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[12px] rounded-[2rem] h-[500px] w-[250px] shadow-xl">
          <div class="w-[124px] h-[15px] bg-gray-800 top-0 rounded-b-[0.8rem] left-1/2 -translate-x-1/2 absolute"></div>
          <div class="h-[38px] w-[2.5px] bg-gray-800 absolute -start-[14px] top-[103px] rounded-s-lg"></div>
          <div class="h-[38px] w-[2.5px] bg-gray-800 absolute -start-[14px] top-[148px] rounded-s-lg"></div>
          <div class="h-[54px] w-[2.5px] bg-gray-800 absolute -end-[14px] top-[118px] rounded-e-lg"></div>
          <div class="rounded-[1.8rem] overflow-hidden w-[226px] h-[472px] bg-white dark:bg-gray-800">
            <img src="mockup.png" class="dark:hidden w-[226px] h-[472px]" alt="" />
            <img src="mockup-dark.png" class="hidden dark:block w-[226px] h-[472px]" alt="" />
          </div>
        </div>
        <div class="relative hidden md:block mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] shadow-xl">
          <div class="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
          <div class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
          <div class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
          <div class="h-[64px] w-[3px] bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
          <div class="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white dark:bg-gray-800">
            <img src="mockup.png" class="dark:hidden w-[272px] h-[572px]" alt="" />
            <img src="mockup-dark.png" class="hidden dark:block w-[272px] h-[572px]" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <div class="bg-white dark:bg-gray-900 w-screen h-full md:h-screen overflow-x-hidden overflow-y-scroll md:overflow-y-hidden">
      <Header />
      <Body />
    </div>
  )
}

export default App;
