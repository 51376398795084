import { useEffect, useState } from "react";

function ModeToggle() {

  const [darkMode, setDarkMode] = useState()

  useEffect(() => {
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark')
      localStorage.theme = 'dark'
      setDarkMode(true)
    } else {
      document.documentElement.classList.remove('dark')
      localStorage.theme = 'light'
      setDarkMode(false);
    }
  }, [])

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark')
      localStorage.theme = 'dark';
    } else {
      document.documentElement.classList.remove('dark')
      localStorage.theme = 'light';
    }
  }, [darkMode])

  return <button onClick={() => setDarkMode((prev) => !prev)} type="button" class="group flex items-center text-emerald-600 hover:text-emerald-700 font-medium dark:text-emerald-700 dark:hover:text-emerald-600">
    {darkMode ? <svg class="flex-shrink-0 size-6" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <circle cx="12" cy="12" r="4"></circle>
      <path d="M12 2v2"></path>
      <path d="M12 20v2"></path>
      <path d="m4.93 4.93 1.41 1.41"></path>
      <path d="m17.66 17.66 1.41 1.41"></path>
      <path d="M2 12h2"></path>
      <path d="M20 12h2"></path>
      <path d="m6.34 17.66-1.41 1.41"></path>
      <path d="m19.07 4.93-1.41 1.41"></path>
    </svg> : <svg class="flex-shrink-0 size-6" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z"></path>
    </svg>}
  </button>
}

export default function Header() {

  return (
    <header>
      <nav class="px-4 lg:px-6 py-4">
        <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <a href="/" class="flex items-center">
            <img src="textlogo.png" className="self-center h-12" />
          </a>
          <div class="flex items-center lg:order-2">
            <ModeToggle />
          </div>
        </div>
      </nav>
    </header>
  )
}